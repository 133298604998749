import * as React from 'react'

import { Layout, SEO, NavBar, Menu, ScreenController } from '../modules'
import { Router } from '../components'
import { Typography } from '../fragments'
import { Hi, About, Projects, Contacts, Resume, Preview } from '../screens'

import { home } from '../lib/seo'
import { useRouter } from '../hooks'
import { APP_VERSION } from '../app.config'

import '../styles/index.scss'

const IndexPage: React.FC = (): JSX.Element => {
  const { getPage } = useRouter()
  const [show, setShow] = React.useState(false)
  const [blockPreview, setBlockPreview] = React.useState(false)
  const [showPreview, setShowPreview] = React.useState(true)

  React.useEffect(() => {
    const id = setTimeout(() => {
      setShow(true)
    }, 1000 * 3)
    return () => clearInterval(id)
  }, [])
  React.useEffect(() => {
    const id = setTimeout(() => {
      setShowPreview(false)
    }, 1000 * 3 - 600)
    return () => clearInterval(id)
  }, [])

  React.useEffect(() => {
    const date = new Date()
    const lastView = new Date(Number(localStorage.getItem('last_view')))
    if (date.toLocaleDateString() === lastView.toLocaleDateString()) {
      setShow(true)
      setShowPreview(false)
      setBlockPreview(true)
    }
    localStorage.setItem('last_view', Number(date).toString())
  }, [])

  return (
    <>
      <SEO lang="en" {...home} />
      {/* <Layout>
        {blockPreview || <Preview show={showPreview} />}
        <main className="home" style={{ display: show ? 'flex' : 'none' }}>
          <NavBar />

          <div className="desk-menu">
            <Menu />
          </div>
          {getPage() === 3 || (
            <Router initialPage={0}>
              <Hi />
              <About />
              <Resume />
              <Projects />
              <div />
              <Contacts />
            </Router>
          )}
        </main>
        <Typography className="alpha">{APP_VERSION}</Typography>

        <ScreenController />
      </Layout> */}
      <Layout>
        <div className="page-404">
          <SEO title="404: Not found" />
          <div className="blok-404">
            <Typography size="large" component="h1" className="title-404">
              503
            </Typography>
            <Typography size="medium">Temporary is not available</Typography>
            <Typography className="info-404">
              Sorry for the inconvenience.
            </Typography>
            <Typography>We are working on it!</Typography>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default IndexPage
